<style scoped lang="scss">

</style>

<template>
  <div class="page-maintenance-dayworks">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fl">
                <el-button-group class="mrgr5 mrgb5">
                  <el-button :class="$root.isPc ? '' : 'mobile-group-button'" type="primary"
                    :plain="typeIndex !== 0" @click="switchType(0)" size="medium">按日期</el-button>
                  <el-button :class="$root.isPc ? '' : 'mobile-group-button'" type="primary" 
                    :plain="typeIndex !== 1" @click="switchType(1)" size="medium">按姓名</el-button>
                </el-button-group>
            </div>
            <div class="fr">
                <span v-show="typeIndex === 0">
                    <el-date-picker class="c-el-input mrgr5 mrgb5" v-model="selectDate" :clearable="false" type="date" 
                        size="medium" placeholder="选择日期" @change="_search"></el-date-picker>
                </span>
                <span v-show="typeIndex === 1">
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="员工的姓名/手机号" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        align="right">
                    </el-date-picker>
                </span>
                <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                <el-button class="mrgr5 mrgb5" type="warning" size="medium" @click="_updateState()"><i class="el-icon-refresh"></i> 更新状态</el-button>
                <!--<el-button class="mrgb5" type="warning" size="medium" @click="openExportExcel()"><i class="fa fa-sign-out"></i> 导出</el-button>-->
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="medium">
        <el-table-column label="ID" prop="id" min-width="120"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="联系电话" prop="tel"></el-table-column>
        <el-table-column label="日期" prop="date"></el-table-column>
        <el-table-column label="首次工作时间" prop="startTime"></el-table-column>
        <el-table-column label="最后工作时间" prop="endTime"></el-table-column>
        <el-table-column label="状态" prop="statusDesc"></el-table-column>
    </el-table>

    <!--导出excel-->
    <export-to-excel :ref="refExportToExcel"></export-to-excel>
  </div>
</template>

<script>
  import permission from "@/mixins/permission"
  import enumConfigs from "@/mixins/enumConfigs"
  import * as funMaintenance from '@/api/maintenance'
  import moment from "moment"
  import ExportToExcel from "@/components/Excels/JsonToExcel"
  import { AnalysisMoveTask } from "@/mixins/commonPageExport"
  export default {
    name: "pageMaintenanceDayWorks",
    mixins: [permission, enumConfigs],
    components: { ExportToExcel },
    data() {
      let that = this
      return {
        refExportToExcel: "refDayWorksToExportExcel",
        tableData: [],
        listQuery: {
            area: "",
            keywords: "",
            startDate: "",
            endDate: ""
        },
        selectedTime: [],
        pickerOptions: {
            shortcuts: [
                {
                    text: "昨天",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "days")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一周",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "weeks")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近三月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 3, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近半年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 6, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "years")
                        that.setQueryTime(timeArr)
                    }
                }
            ]
        },
        typeIndex: 0,
        selectDate: moment().subtract(1, "d").format("YYYY-MM-DD")
      }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    activated() {
        let params = this.$route.params
        if (params) {
            if (params.keyword) {
                this.listQuery.keywords = params.keyword
                this.typeIndex = 1
            }
            if (params.selectedTime && params.selectedTime.length == 2) {
                this.selectedTime = params.selectedTime
            }
            this._search()
        } else {
            this._search()
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.startDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        setSelectDate() {
            if (this.selectDate && moment(this.selectDate).isValid()) {
                this.listQuery.startDate = moment(this.selectDate).format("YYYY-MM-DD 00:00:00")
                this.listQuery.endDate = moment(this.selectDate).format("YYYY-MM-DD 23:59:59")
            }
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.startDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.startDate, this.listQuery.endDate]
        },
        getDataList () {
            if (this.typeIndex === 0) {
                this.setSelectDate()
            }
            if (this.typeIndex === 1) {
                this.setSelectedTime()
            }
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funMaintenance.GetEmployeeDayWorks(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map(x => {
                        x.date = moment(x.date).format("YYYY-MM-DD")
                        let sdItem = this.arrEmployeeDayWorkStatus.find(sd => sd.value === x.status)
                        x.statusDesc = sdItem && sdItem.desc || ""
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        switchType(type) {
            this.typeIndex = type || 0
            this.listQuery.keywords = ""
        },
        _updateState() {
            if (this.typeIndex === 0) {
                this.setSelectDate()
            }
            if (this.typeIndex === 1) {
                this.setSelectedTime()
            }
            let params = {
                area: this.listQuery.area,
                startDate: this.listQuery.startDate,
                endDate: this.listQuery.endDate
            }
            if (!params.startDate || !params.endDate) {
                this.warningMsg("请选择更新时间段!")
                return false
            }
            funMaintenance.HandleDayWork(params).then(() => {
                this.getDataList()
                this.successMsg("工作状态更新成功!")
            })
        },
        _search() {
            if (this.typeIndex === 0 && !this.selectDate) {
                this.warningMsg("请选择查询日期!")
                return false
            }
            if (this.typeIndex === 1 && !this.listQuery.keywords) {
                this.warningMsg("请输入员工姓名或者手机号!")
                return false
            }
            this.getDataList()
        }
    }
  }
</script>